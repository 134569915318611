function showNavigationWhenScrollingUp() {
  let previousScrollPosition = window.pageYOffset;
  const navigation = document.querySelector(".navigation__main-wrapper");

  window.addEventListener("scroll", () => {
    const start = 100;
    const currentScrollPosition = window.pageYOffset;

    if (
      previousScrollPosition > currentScrollPosition ||
      currentScrollPosition < start
    ) {
      document
        .querySelector(".navigation")
        .classList.remove("navigation--hidden");
    } else {
      if (navigation.classList.contains("navigation__main-wrapper--visible")) {
        return;
      }
      document.querySelector(".navigation").classList.add("navigation--hidden");
    }

    previousScrollPosition = currentScrollPosition;
  });
}

export default showNavigationWhenScrollingUp;
