function goTop() {
  const button = document.querySelector(".go-top__button");

  button.addEventListener("click", () => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  });
}

export default goTop;
