function readMore() {
  const readMoreSections = Array.from(
    document.querySelectorAll(".read-more-section")
  );
  readMoreSections.forEach((element) => {
    const readMoreButton = element.querySelector(".read-more-button");
    readMoreButton.addEventListener("click", () => {
      element.innerHTML = element.dataset.text;
      readMoreButton.style.display = "none";
    });
  });
}

export default readMore;
