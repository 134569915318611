import galleryFade from "./gallery-fade.js";
import showNavigationWhenScrollingUp from "./show-navigation-when-scrolling-up.js";
import toggleBurgerNavigation from "./toggle-burger-navigation.js";
import snapSlider from "./snap-slider.js";
import goTop from "./go-top.js";
import formParams from "./form-params.js";
import flickitySlider from "./flickity-slider.js";
import readMore from "./read-more.js";
import eldoradoFactsSlider from "./eldorado-facts-slider.js";
import testimonialsSlider from "./testimonials-slider.js";
import scrollFilter from "./scroll-filter.js";
import autoCloseFilter from "./auto-close-filter.js";
import imageSlider from "./image-slider.js";
import ferienhausSlider from "./ferienhaus-slider.js";
import relatedTravelsSlider from "./related-travels-slider.js";
import sibFormNewsletter from "./sib-form-newsletter.js";

document.addEventListener("DOMContentLoaded", () => {
  galleryFade();
  showNavigationWhenScrollingUp();
  toggleBurgerNavigation();
  snapSlider();
  goTop();
  formParams();
  flickitySlider();
  readMore();
  eldoradoFactsSlider();
  testimonialsSlider();
  scrollFilter();
  autoCloseFilter();
  imageSlider();
  ferienhausSlider();
  relatedTravelsSlider();
  sibFormNewsletter;
});
