function formParams() {
  const form = document.getElementById("contact-form");

  if (!form) {
    return;
  }

  const submitMessage = document.getElementsByClassName(
    "contact-form__submit-message"
  );

  var url = new URL(window.location.href);
  var params = new URLSearchParams(url.search);
  const bookingNr = params.get("buchungsnummer");
  const travelTimes = params.get("reisezeit");

  const bookingNrField = document.querySelector(
    ".contact-form__bookingnumber-field"
  );
  const travelTimesField = document.querySelector(
    ".contact-form__traveltimes-field"
  );

  if (bookingNr) {
    bookingNrField.style.display = "block";
    bookingNrField.querySelector("input").value =
      "Buchungsnummer: " + bookingNr;
    form.scrollIntoView({ behavior: "smooth", block: "start" });
  }

  if (travelTimes) {
    travelTimesField.style.display = "block";
    travelTimesField.querySelector("input").value =
      "Reisezeit: " + travelTimes.split("bis").join(" – ");
  }

  if (submitMessage.length) {
    form.scrollIntoView({ behavior: "smooth", block: "start" });
  }
}

export default formParams;
