function toggleBurgerNavigation() {
  const burger = document.querySelector(".navigation__burger");
  const burgerClose = document.querySelector(".navigation__burger-close");
  const navigation = document.querySelector(".navigation__main-wrapper");

  if (!burger || !navigation) {
    return;
  }

  burgerClose.style.top = burger.offsetTop;

  const burgerNavOpenClass = "navigation__main-wrapper--visible";
  const burgerNavCloseClass = "navigation__main-wrapper--hidden";
  burger.addEventListener("click", () => {
    navigation.classList.add(burgerNavOpenClass);
    navigation.classList.remove(burgerNavCloseClass);
  });

  burgerClose.addEventListener("click", () => {
    navigation.classList.remove(burgerNavOpenClass);
    navigation.classList.add(burgerNavCloseClass);
  });

  window.addEventListener("resize", () => {
    burgerClose.style.top = burger.offsetTop;

    if (window.innerWidth > 1000) {
      navigation.classList.remove(burgerNavOpenClass);
      navigation.classList.remove(burgerNavCloseClass);
    }
  });
}

export default toggleBurgerNavigation;
