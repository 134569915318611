function scrollFilter() {
  const filterItems = Array.from(
    document.querySelectorAll(".travels-filter__button")
  );
  const previousPagePositionFilter = window.localStorage.getItem(
    "previousPagePositionFilter"
  );
  const dateButton = document.querySelector(".travels-filter__dates-button");
  if (!dateButton) {
    return;
  }

  if (previousPagePositionFilter) {
    window.scrollTo(0, previousPagePositionFilter);
    window.localStorage.removeItem("previousPagePositionFilter");
  }

  filterItems.forEach((button) => {
    button.addEventListener("click", () => {
      window.localStorage.setItem(
        "previousPagePositionFilter",
        window.pageYOffset
      );
    });
  });
  dateButton.addEventListener("click", () => {
    window.localStorage.setItem(
      "previousPagePositionFilter",
      window.pageYOffset
    );
  });
}

export default scrollFilter;
