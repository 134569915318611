function snapSlider() {
  const sliders = Array.from(document.querySelectorAll(".slider"));

  if (!sliders.length) {
    return;
  }

  sliders.forEach((slider) => {
    const scroller = slider.querySelector(".slider__cards");
    const prevBtn = slider.querySelector(".slider__button--left");
    const nextBtn = slider.querySelector(".slider__button--right");
    const itemWidth = slider.querySelector(".slider__card").clientWidth;

    // handle window resize
    nextBtn.addEventListener("click", scrollToNextItem);
    prevBtn.addEventListener("click", scrollToPrevItem);

    function scrollToNextItem() {
      scroller.scrollBy({ left: itemWidth, top: 0, behavior: "smooth" });
    }

    function scrollToPrevItem() {
      scroller.scrollBy({ left: -itemWidth, top: 0, behavior: "smooth" });
    }
  });
}

export default snapSlider;
