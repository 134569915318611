function galleryFade() {
  const galleries = Array.from(
    document.querySelectorAll(".header-image-slider__gallery")
  );

  galleries.forEach((gallery) => {
    const images = Array.from(
      gallery.querySelectorAll(".header-image-slider__image")
    );
    const lastImage = images.length - 1;
    let activeImageIndex = 0;
    let start, previousTimeStamp;

    function step(timestamp) {
      if (start === undefined) start = timestamp;
      const elapsed = timestamp - start;
      activeImageIndex = Math.floor((elapsed / 4000) % (lastImage + 1));
      if (
        !images[activeImageIndex].classList.contains(
          "header-image-slider__image--active"
        )
      ) {
        const previousActiveImage = gallery.querySelector(
          ".header-image-slider__image--active"
        );
        if (previousActiveImage) {
          previousActiveImage.classList.remove(
            "header-image-slider__image--active"
          );
        }
        images[activeImageIndex].classList.add(
          "header-image-slider__image--active"
        );
      }

      previousTimeStamp = timestamp;
      window.requestAnimationFrame(step);
    }

    window.requestAnimationFrame(step);
  });
}

export default galleryFade;
