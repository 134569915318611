function autoCloseFilter() {
    const filterItems = Array.from(document.querySelectorAll(".travels-filter__group"))
    const dateButton = document.querySelector(".travels-filter__dates-button")

    if(!dateButton) {
        return
    }

    dateButton.addEventListener("click", () => {
        filterItems.find(item => item.open).removeAttribute("open")
    })

    document.body.addEventListener("click", () => {
        filterItems.filter(item => item.open).forEach(item => {
            item.removeAttribute("open")
        })
    })

    filterItems.forEach(item => {
        item.addEventListener("click", (event) => {
            event.stopPropagation()
            const otherFilterIsOpen = filterItems.find(_item => _item.open && _item !== item)
            if(otherFilterIsOpen) {
                otherFilterIsOpen.removeAttribute("open")
            }
        })
    })
}

export default autoCloseFilter